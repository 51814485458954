<template>
    <footer class="site-footer">
        <div class="container">
            <div class="custom-row">
                <div class="site-footer-left">
                    <ul>
                        <li><a href="/">{{ constants.HOME_MENU }}</a></li>
                        <li><a data-bs-toggle="modal" data-bs-target="#ourMission">{{ constants.ABOUT_ME_MENU }}</a>
                        </li>
                        <li><router-link to="/services">{{ constants.SERVICES_MENU }}</router-link></li>
                        <li><router-link to="/blog">{{ constants.BLOG_MENU }}</router-link></li>
                        <li><a href="#" data-bs-toggle="modal" data-bs-target="#getTouch">{{ constants.CONTACT_US_MENU
                                }}</a></li>
                    </ul>
                </div>
                <div class="site-footer-right">
                    <p>{{ constants.COPYRIGHT_TEXT }}</p>
                </div>
            </div>
        </div>
    </footer>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import constants from '@/utils/en-US-User.json';
export default defineComponent({
    name: 'FooterComponent',
    data() {
        return {
            constants,
        };
    },
});
</script>