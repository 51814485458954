<template>
    <header class="site-header inner-pages-header" v-if="!$route.path.startsWith('/authority')">
        <div class="container">
            <div class="custom-row">
                <div class="logo"><router-link to="/" :class="{ active: activeIcon === 'home' }"
                        @click="setActive('home')"><img src="../assets/img/logo.svg" alt="" /></router-link></div>
                <div class="header-right">
                    <div class="menu_icon" id="menuOpen" @click="toggleNavClass"></div>
                    <nav>
                        <ul>
                            <li><router-link to="/" :class="{ active: activeIcon === 'home' }"
                                    @click="setActive('home')">{{ constants.HOME_TEXT }}</router-link></li>
                            <li><a data-bs-toggle="modal" data-bs-target="#ourMission">{{ constants.ABOUT_ME }}</a></li>
                            <li><router-link to="/services" :class="{ active: activeIcon === 'services' }"
                                    @click="toggleActive('services')">{{ constants.SERVICES_MENU }}</router-link></li>
                            <li><router-link to="/blog" :class="{ active: activeIcon === 'blog' }"
                                    @click="setActive('blog')">{{ constants.BLOG_MENU }}</router-link></li>
                            <li><a href="#" data-bs-toggle="modal" data-bs-target="#getTouch">{{
                                constants.CONTACT_US_MENU }}</a></li>
                            <li><router-link to="/pay" class="primary-btn">{{ constants.PAY_MENU }}</router-link></li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <div class="main-message-box successful-box show" v-if="$store.state.showPopup">
            <button class="close-message" @click="$store.commit('hidePopup')"></button>
            <div class="main-message-img">
                <img src="../assets/img/successful-message-icon.svg" alt="" />
            </div>
            <div class="main-message-content">
                <h5>{{ constants.SUCCESSFUL_TEXT }}</h5>
                <p>{{ constants.THE_FORM_IS_SUCCESSFULLY_SUBMITTED_TEXT }}</p>
            </div>
        </div>
    </header>

    <header class="admin-site-header" v-else>
        <div class="full-container">
            <div class="custom-row">
                <div class="logo">
                    <a href="index.html">
                        <img src="../assets/img/logo.svg" alt="">
                    </a>
                </div>
                <div class="header-right">
                    <nav>
                        <ul>
                            <li class="hdr-dropdown">
                                <div class="login-user-info">
                                    <a href="#">
                                        <i class="small-user-img">
                                            <img src="../assets/img/dummy/user-img.jpg" alt="">
                                        </i>
                                        <span>{{ firstname }}</span>
                                    </a>
                                </div>
                                <div class="hdr-dropdown-outer">
                                    <div class="hdr-dropdown-inner">
                                        <a @click="logout">
                                            {{ constants.LOGOUT_LINK }}
                                        </a>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
        <div class="main-message-box successful-box show" v-if="$store.state.showPopup">
            <button class="close-message" @click="$store.commit('hidePopup')"></button>
            <div class="main-message-img">
                <img src="../assets/img/successful-message-icon.svg" alt="" />
            </div>
            <div class="main-message-content">
                <h5>{{ constants.SUCCESSFUL_TEXT }}</h5>
                <p>{{ constants.LOGGED_IN_SUCCESSFULLY }}</p>
            </div>
        </div>
    </header>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import constants from '@/utils/en-US-User.json';
import { Store } from 'vuex';
import { ComponentCustomProperties } from 'vue';

// Define custom properties for the Vue component instance
declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        $store: Store<any>;
    }
}

export default defineComponent({
    name: 'HeaderComponent',
    data() {
        return {
            constants,
            accessToken: localStorage.getItem('accessToken'),
            firstname: localStorage.getItem('firstname'),
        };
    },
    methods: {
        // closePopup() {
        //     this.$store.commit('hidePopup', false);
        // },
        logout() {
            localStorage.clear();
            this.$router.push({ name: 'LoginView' });
        },
    },
    props: {
        activeIcon: String
    },
    setup(props, { emit }) {
        const store = useStore();

        const toggleNavClass = () => {
            store.commit('toggleNavClass');
        };

        const setActive = (icon: string) => {
            emit('update:activeIcon', icon);
        };

        const toggleActive = (icon: string) => {
            emit('update:activeIcon', icon === props.activeIcon ? '' : icon);
        };

        return {
            setActive,
            toggleActive,
            toggleNavClass
        };
    }
});
</script>
