<template>
    <section id="mainContainer">
        <HeaderComponent></HeaderComponent>
        <section class="admin-mid-container">
            <div class="custom-row admin-page-row">
                <LeftSidebarComponent :activeIcon.sync="activeIcon"></LeftSidebarComponent>
                <section class="right-sidebar">
                    <div class="right-sidebar-in">
                        <h1>
                            {{ constants.SERVICES_TEXT }}
                        </h1>
                        <div class="form-content">
                            <form>
                                <div class="input-field input-width-95">

                                    <label> {{ constants.TITLE_LABEL }}</label>

                                    <input type="text" placeholder="Enter the text here" v-model="serviceMainTitle"
                                        required>
                                </div>
                                <div class="input-field input-width-95">

                                    <label> {{ constants.SUB_CONTENT_LABEL }}</label>

                                    <textarea placeholder="Enter the text here" v-model="serviceMainSubContent"
                                        required></textarea>
                                </div>
                                <div class="form-submit-btn-txt">
                                    <div class="button-left">
                                        <button class="save-btn-txt txt-btn" type='button' @click.prevent="saveServiceMain">
                                            {{ constants.SAVE_BUTTON }}
                                        </button>
                                        <button class="cancel-btn-txt txt-btn" id="cancelClickOne">
                                            {{ constants.CANCEL_BUTTON }}
                                        </button>
                                    </div>
                                </div>
                                <div class="add-list-row">
                                    <!-- Loop Box Start-->
                                    <div class="add-list-box" v-for="(service, index) in services" :key="index"
                                        :class="{ 'add-list-show': service.editing }">
                                        <div class="add-list-box-top">
                                            <h6>{{ service.title }}</h6>
                                            <ul>
                                                <li>
                                                    <button type="button" id="editClickOne"
                                                        @click="startEditing(index)">
                                                        {{ constants.EDIT_BUTTON }}
                                                    </button>
                                                </li>
                                                <li>
                                                    <button type="button" @click="deleteService(index, $event)">
                                                        {{ constants.DELETE_BUTTON }}
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                        <!-- After edit button click show this section -->
                                        <div class="add-list-box-btm" v-if="service.editing">
                                            <div class="input-field ">

                                                <label> {{ constants.TITLE_LABEL }}</label>

                                                <input type="text" placeholder="Enter the text here"
                                                    v-model="service.title">
                                            </div>
                                            <div class="input-field ">

                                                <label> {{ constants.SHORT_DESCRIPTION_LABEL }}</label>

                                                <textarea placeholder="Description"
                                                    v-model="service.short_description"></textarea>
                                            </div>
                                            <div class="input-field ">

                                                <label> {{ constants.DESCRIPTION_LABEL }}</label>

                                                <ckeditor placeholder="Description" class="text-editer" :editor="editor"
                                                    v-model="service.description" :config="editorConfig">
                                                </ckeditor>
                                                <!-- <textarea placeholder="Description"
                                                    v-model="service.description"></textarea> -->
                                            </div>
                                            <div class="input-field input-width-95">
                                                <label> {{ constants.UPLOAD_IMAGE_LABEL }}</label>
                                                <div class="single-img-upload-row">
                                                    <div class="single-img-upload-box img-uploaded">
                                                        <div class="single-img-upload">
                                                        </div>
                                                        <div class="single-img-uploaded">
                                                            <div class="Uploaded-img-edit">
                                                                <input type="file" @change="handleFileUpload($event)"
                                                                    accept="image/*" />
                                                            </div>
                                                            <div class="single-img-uploaded-img">
                                                                <img :src="imageSrc || (service.image as string)"
                                                                    alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="input-field-submit">
                                                <div class="button-center ">
                                                    <button class="secondary-outline-btn">
                                                        {{ constants.CANCEL_BUTTON }}
                                                    </button>
                                                    <button class="primary-outline-btn"
                                                        @click.prevent="submitEdit(index)">
                                                        {{ constants.SUBMIT_BUTTON }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- After edit button click show this section -->
                                    </div>
                                </div>
                                <!-- Add new Form and click Add more button show -->
                                <div id="addNewItemShow" :class="{ 'addnewitemshow': formOpen }" v-if="formOpen">
                                    <div class="add-list-bg">
                                        <div class="input-field ">

                                            <label> {{ constants.TITLE_LABEL }}</label>

                                            <input type="text" placeholder="Enter the text here"
                                                v-model="newService.title">
                                        </div>
                                        <div class="input-field ">

                                            <label> {{ constants.SHORT_DESCRIPTION_LABEL }}</label>

                                            <textarea placeholder="Description"
                                                v-model="newService.short_description"></textarea>
                                        </div>
                                        <div class="input-field ">

                                            <label> {{ constants.DESCRIPTION_LABEL }}</label>

                                            <ckeditor placeholder="Description" class="text-editer" :editor="editor"
                                                v-model="newService.description" :config="editorConfig">
                                            </ckeditor>
                                            <!-- <textarea placeholder="Description" v-model="newService.description"></textarea> -->
                                        </div>
                                        <div class="input-field input-width-95">
                                            <label> {{ constants.UPLOAD_IMAGE_LABEL }}</label>
                                            <div class="single-img-upload-row">
                                                <div class="single-img-upload-box">
                                                    <div class="single-img-upload">
                                                        <div class="single-img-upload-icon" v-if="!imageSrc">
                                                            <i>
                                                                <img src="../../assets/img/add-img-icon.svg" alt="" />
                                                            </i>
                                                            <span>{{ constants.ADD_IMAGE_LABEL }}</span>
                                                        </div>
                                                        <div class="single-img-upload-input">
                                                            <input type="file" @change="handleFileUpload($event)"
                                                                accept="image/*">
                                                        </div>
                                                        <div class="single-img-uploaded-img">
                                                            <img :src="imageSrc" alt=""
                                                                style="max-width: 100%;max-height: 100%;display: block;">
                                                        </div>
                                                    </div>
                                                    <div class="single-img-uploaded" v-if="imageSrc">
                                                        <div class="Uploaded-img-edit">
                                                            <input type="file" @change="handleFileUpload($event)"
                                                                accept="image/*" />
                                                        </div>
                                                        <div class="single-img-uploaded-img">
                                                            <img :src="imageSrc" alt=""
                                                                style="max-width: 100%; max-height: 100%; display: block;" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="input-field-submit">
                                            <div class="button-center ">
                                                <button class="secondary-outline-btn" @click="formOpen = !formOpen">
                                                    {{ constants.CANCEL_BUTTON }}
                                                </button>
                                                <button type="button" class="primary-outline-btn"
                                                    @click="addService($event)">
                                                    {{ constants.SUBMIT_BUTTON }}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- Add new Form -->
                                <div class="add-more-row-btn">
                                    <button id="addNewItem" @click="formOpen = !formOpen">
                                        {{ constants.PLUS }}
                                        {{ constants.ADD_MORE_BUTTON }}
                                    </button>
                                </div>

                            </form>
                        </div>
                    </div>
                </section>
            </div>
        </section>
        <footer class="admin-site-footer">
            <div class="container">
                {{ constants.COPYRIGHT_TEXT }}
            </div>
        </footer>
    </section>
</template>


<script lang="ts">
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import adminConstants from '@/utils/en-US-Admin.json';
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import LeftSidebarComponent from "@/components/AdminLeftSideBarComponent.vue";
import { API_BASE_URL } from '@/config/api.config';
import customJSFunction from "@/assets/js/custom.js";
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { useRouter } from 'vue-router';


interface Service {
    id: number;
    title: string;
    short_description: string;
    description: string;
    image: File | string;
    editing: boolean;
    original?: Service;
    [key: string]: any;
}

interface ServiceMain {
    id: number;
    title: string;
    sub_content: string;
    services: Service[];
}

export default {
    name: 'AdminServicesView',
    components: {
        HeaderComponent,
        FooterComponent,
        LeftSidebarComponent
    },
    data() {
        return {
            editor: ClassicEditor,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'underline',
                        'link',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'outdent',
                        'indent',
                        '|',
                        'undo',
                        'redo',
                        '|',
                        'insertTable',
                    ]
                },
            }
        };
    },
    setup() {
        const activeIcon = ref('services');
        const constants = ref(adminConstants);
        const selectedFile = ref<File | null>(null);
        const services = ref<Service[]>([]);
        const serviceMain = ref<ServiceMain | null>(null);
        const formOpen = ref(false);
        const newService = ref({
            title: '',
            short_description: '',
            description: '',
            image: null as File | null,
        });
        const imageSrc = ref('');
        const router = useRouter();


        const serviceMainTitle = computed({
            get: () => serviceMain.value ? serviceMain.value.title : '',
            set: (value) => {
                if (serviceMain.value) {
                    serviceMain.value.title = value;
                }
            },
        });

        const serviceMainSubContent = computed({
            get: () => serviceMain.value ? serviceMain.value.sub_content : '',
            set: (value) => {
                if (serviceMain.value) {
                    serviceMain.value.sub_content = value;
                }
            },
        });


        const handleFileUpload = (event: Event) => {
            const target = event.target as HTMLInputElement;
            if (target && target.files && target.files.length > 0) {
                selectedFile.value = target.files[0];
                const reader = new FileReader();
                reader.readAsDataURL(selectedFile.value);
                reader.onload = (e) => {
                    if (e.target && typeof e.target.result === 'string') {
                        imageSrc.value = e.target.result;
                    }
                }
            }
        };

        const editSlide = (index: number) => {
            services.value.forEach((service, i) => {
                service.editing = i === index ? !service.editing : false;
            });
        };

        const saveServiceMain = async () => {

            if (serviceMain.value && serviceMain.value.id) {
                // Update existing record
                if (!serviceMain.value.title || !serviceMain.value.sub_content) {
                    alert('Please fill all the fields');
                    return;
                } else {
                    const response = await axios.patch(`${API_BASE_URL}/service_main/${serviceMain.value.id}/`, serviceMain.value);
                    serviceMain.value = response.data;
                    fetchServiceMain();

                }
            } else if (serviceMain.value) {
                // Create new record
                if (!serviceMain.value.title || !serviceMain.value.sub_content) {
                    alert('Please fill all the fields');
                    return;
                } else {
                    const newRecord = {
                        title: serviceMainTitle.value,
                        sub_content: serviceMainSubContent.value,
                    };
                    const response = await axios.post(`${API_BASE_URL}/service_main/`, newRecord);
                    serviceMain.value = response.data;
                    fetchServiceMain();
                }
            }
        };

        const fetchServiceMain = async () => {
            const response = await axios.get(`${API_BASE_URL}/service_main/`);
            if (response.data && response.data.length > 0) {
                serviceMain.value = response.data[0];
            } else {
                serviceMain.value = {
                    id: 0,
                    title: '',
                    sub_content: '',
                    services: [],
                };
            }
        };

        const startEditing = (index: number) => {
            editSlide(index);
            services.value[index].original = { ...services.value[index] };
        };

        const fetchServices = async () => {
            const response = await axios.get(`${API_BASE_URL}/services/`);
            services.value = response.data.sort((a: { id: number }, b: { id: number }) => a.id - b.id);
            if (services.value.length === 0) {
                formOpen.value = true;

            }
        };

        const updateServiceMain = async () => {
            if (serviceMain.value !== null) {
                const response = await axios.patch(`${API_BASE_URL}/service_main/${serviceMain.value.id}/`, serviceMain.value);
                serviceMain.value = response.data;
            }
        };

        const updateService = async (service: Service) => {
            const response = await axios.patch(`${API_BASE_URL}/services/${service.id}/`, service);
            const updatedService = response.data;
            const index = services.value.findIndex(s => s.id === updatedService.id);
            services.value.splice(index, 1, updatedService);
        };

        const addService = async (event: Event) => {
            const formData = new FormData();
            formData.append('title', newService.value.title);
            formData.append('short_description', newService.value.short_description);
            formData.append('description', newService.value.description);
            if (selectedFile.value instanceof File) {
                formData.append('image', selectedFile.value);
            }
            // if image is not available do not hit the api instead show an alert
            if (!selectedFile.value) {
                alert('Please upload an image');
                return;
            } else if (newService.value.title === '' || newService.value.short_description === '' || newService.value.description === '') {
                alert('Please fill all the fields');
                return;
            } else {
                const response = await axios.post(`${API_BASE_URL}/services/`, formData);
                services.value.push(response.data);
                newService.value.title = '';
                newService.value.short_description = '';
                newService.value.description = '';
                newService.value.image = null;
                selectedFile.value = null;
                imageSrc.value = '';
                formOpen.value = false;
            }
            event.preventDefault();

        };

        const submitEdit = async (index: number) => {
            const service = services.value[index];
            const formData = new FormData();

            for (const key in service) {
                if (service.original && service[key] !== service.original[key]) {
                    formData.append(key, service[key]);
                }
            }

            if (selectedFile.value instanceof File) {
                formData.append('image', selectedFile.value);
            }

            const response = await axios.patch(`${API_BASE_URL}/services/${service.id}/`, formData);
            const updatedService = response.data;
            selectedFile.value = null;
            services.value.splice(index, 1, updatedService);

            service.editing = false;
            delete service.original;
        };


        const deleteService = async (index: number, event: Event) => {
            const service = services.value[index];
            const response = await axios.delete(`${API_BASE_URL}/services/${service.id}/`);
            services.value.splice(index, 1);
            event.preventDefault();
        };


        onMounted(async () => {
            await fetchServiceMain();
            await fetchServices();
            const isLoggedIn = localStorage.getItem('isLoggedIn');
            if (isLoggedIn !== "true") {
                router.push({ name: 'LoginView' });
            }

        });

        return {
            activeIcon,
            constants,
            selectedFile,
            services,
            serviceMain,
            formOpen,
            newService,
            imageSrc,
            handleFileUpload,
            editSlide,
            fetchServiceMain,
            addService,
            deleteService,
            submitEdit,
            updateService,
            serviceMainSubContent,
            serviceMainTitle,
            updateServiceMain,
            startEditing,
            saveServiceMain

        };
    },
};
</script>
