export const environment = {
   production: true,
   isSSL: true,
   host: 'www.rediscoveryourthoughts.com',
   apiPort: 3000,
   port: 443,
   dirImg: '/upload',
    imageCdn: {
      ssl: true,
      host: 'media-api.lmdconsulting.com',
      port: 443,
      appName: 'lmd',
      fileType: 'image',
      dirPath: 'download/',
      get getHost() {
        return 'http' + (this.ssl ? 's' : '') + '://' + this.host +
          ((this.port === 80 || this.port === 443) ? '' : ':' + this.port) + '/' +
          this.appName + '/' + this.fileType + '/' + this.dirPath;
      }
    },
    get ImageBase() {
      return 'http' + (this.isSSL ? 's' : '') + '://' + this.host +
      ((this.port === 80 || this.port === 443) ? '' : '') + this.dirImg;
    },
    
  };