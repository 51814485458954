<template>
    <div class="modal fade small-pop get-touch-pop" id="getTouch" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content get-touch-pop-content">
                <h2>{{ constants.GET_IN_TOUCH_TEXT }} <strong class="sub-hd">{{ constants.HAVE_A_QUESTION_TEXT
                        }}</strong></h2>
                <button class="close-pop-btn" data-bs-dismiss="modal" aria-label="Close" @click="resetform()"></button>
                <div class="get-touch-form">
                    <form @submit.prevent="submitForm">
                        <div class="input-field" :class="{ error: titleError && !Name }">
                            <label>{{ constants.NAME_LABEL }}</label>
                            <input type="text" v-model="Name" placeholder="Enter your name" />
                            <span class="error-text" v-if="titleError && !Name">{{ constants.THIS_FIELD_IS_REQUIRED
                                }}</span>
                        </div>
                        <div class="input-field" :class="{ error: (titleError && !Email) || (emailError.length) }">
                            {{ constants.EMAIL_LABEL }}
                            <input type="text" v-model="Email" placeholder="Enter your email"
                                @input="validateEmail(nextClicked)" />
                            <span class="error-text" v-if="titleError && !Email">{{ constants.THIS_FIELD_IS_REQUIRED
                                }}</span>
                            <span class="error-text" v-if="!(titleError && !Email)">{{ emailError }}</span>
                        </div>
                        <div class="input-field" :class="{ error: titleError && !number }">
                            {{ constants.PHONE_NUMBER_LABEL }}
                            <input type="text" v-model="number" placeholder="Enter your Phone Number"
                                @keypress="preventNonNumeric($event)" />
                            <span class="error-text" v-if="titleError && !number">{{ constants.THIS_FIELD_IS_REQUIRED
                                }}</span>
                        </div>
                        <div class="input-field">
                            <label class="optional-label">{{ constants.MESSAGE_LABEL
                                }}<span>{{ constants.OPTIONAL_LABEL }}</span></label>
                            <!-- {{ constants.MESSAGE_LABEL }} -->
                            <textarea v-model="Message" placeholder="Enter your message"></textarea>
                        </div>
                        <div class="input-field-submit">
                            <div class="button-center">

                                <button type="button" class="secondary-outline-btn" @click="resetform()"
                                    data-bs-dismiss="modal">{{ constants.CANCEL_BUTTON }} </button>
                                <button type="submit" class="primary-outline-btn">{{ constants.SUBMIT_BUTTON }}</button>
                                <button type="button" class="dummy-btn" data-bs-dismiss="modal" ref="dummyButton"
                                    style="display: none;"></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script lang="ts">
import axios from 'axios';
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import { API_BASE_URL } from '@/config/api.config';
import constants from '@/utils/en-US-User.json';
import { error } from 'jquery';
import bootstrap from 'bootstrap';
import { Modal } from 'bootstrap';

export default defineComponent({
    name: 'ContactUs',
    data() {
        return {
            constants,
            Name: '',
            Email: '',
            number: '',
            Message: '',
            titleError: false,
            emailError: '',
            nextClicked: false
        };
    },
    methods: {
        preventNonNumeric(event: any) {
            const keyCode = event.keyCode ? event.keyCode : event.which;
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 43 && keyCode !== 45 && keyCode !== 101) { // 43 is for '+', 45 is for '-', 101 is for 'e'
                event.preventDefault();
            }
        },
        validateEmail(nextClicked: boolean) {
            if (nextClicked) {
                if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.Email))) {
                    this.emailError = constants.VALID_EMAIL;
                } else {
                    this.emailError = '';
                }
            }
        },
        async submitForm(event: Event) {
            this.nextClicked = true;
            this.validateEmail(this.nextClicked);
            if (!this.Name || !this.Email || !this.number) {
                // If any field is not filled, show an error message and return
                this.titleError = true;
                return;
            } else {
                this.titleError = false;
            }
            try {
                const response = await axios.post(`${API_BASE_URL}/contactUs/`, {
                    Name: this.Name,
                    Email: this.Email,
                    Number: this.number,
                    Message: this.Message,
                    Created_at: new Date()

                });
                this.resetform();
                this.nextClicked = false;
                (this.$refs.dummyButton as HTMLElement).click();
                this.$store.commit('showPopup')
                setTimeout(() => {
                    this.$store.commit('hidePopup')
                }, 10000)
            } catch (error) {
                console.error(error);
            }
        },
        async resetform() {
            this.Name = '';
            this.Email = '';
            this.number = '';
            this.Message = '';
            this.titleError = false;
            this.emailError = '';
            this.nextClicked = false;
        }
    }
});
</script>
