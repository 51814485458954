import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomePage from '../views/User/HomeView.vue'
import BlogPage from '@/views/User/BlogView.vue'
import BlogReadMore from '@/views/User/BlogReadMoreView.vue'
import ServicesView from '@/views/User/ServicesView.vue'
import PayView from '@/views/User/PayView.vue'
import LoginView from '@/views/Admin/LoginView.vue'
import adminHomeView from '@/views/Admin/HomePageHeroView.vue'
import ContactUsView from '@/views/Admin/ContactUsView.vue'
import AboutUsView from '@/views/Admin/AboutUsView.vue'
import NewsletterSubscribed from '@/views/Admin/NewsletterSubscibed.vue'
import PaymentDetail from '@/views/Admin/PaymentDetail.vue'
import AppointmentView from '@/views/Admin/BookingAppointment.vue'
import AdminServicesView from '@/views/Admin/AdminServicesView.vue'
import AdminBlogView from '@/views/Admin/AdminBlogView.vue'
import AdminBenefitsToGainView from '@/views/Admin/AdminBenefitsToGainView.vue'
import AdminBenefitsOfSubsView from '@/views/Admin/AdminBenefitsOfSubsView.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/pay', // The path you want for the blog page
    name: 'PayView', // The name of the route
    component: PayView,
    props: true // The component to render when the route is visited},
  },
  {
    path: '/blog', // The path you want for the blog page
    name: 'blog', // The name of the route
    component: BlogPage // The component to render when the route is visited
  },
  {
    path: '/BlogReadMore', // The path you want for the blog page
    name: 'BlogReadMore', // The name of the route
    component: BlogReadMore // The component to render when the route is visited
  },
  {
    path: '/services', // The path you want for the blog page
    name: 'ServicesView', // The name of the route
    component: ServicesView // The component to render when the route is visited
  },
  {
    path: '/authority/login', // The path you want for the blog page
    name: 'LoginView', // The name of the route
    component: LoginView // The component to render when the route is visited
  },
  {
    path: '/authority/home', // The path you want for the blog page
    name: 'adminHomeView', // The name of the route
    component: adminHomeView // The component to render when the route is visited
  },
  {
    path: '/authority/contactUs',
    name: 'ContactUsView',
    component: ContactUsView
  },
  {
    path: '/authority/aboutUs',
    name: 'AboutUsView',
    component: AboutUsView
  },
  {
    path: '/authority/newsletter',
    name: 'NewsletterSubscribed',
    component: NewsletterSubscribed
  },
  {
    path: '/authority/appointment',
    name: 'AppointmentView',
    component: AppointmentView
  },
  {
    path: '/authority/payments',
    name: 'PaymentDetail',
    component: PaymentDetail
  },
  {
    path: '/authority/services',
    name: 'AdminServicesView',
    component: AdminServicesView
  },
  {
    path:'/authority/blog',
    name:'adminBlog',
    component: AdminBlogView
  },
  {
    path: '/authority/benefitsToGain',
    name: 'AdminBenefitsToGainView',
    component: AdminBenefitsToGainView
  },
  {
    path: '/authority/benefitsOfSubs',
    name: 'AdminBenefitsOfSubsView',
    component: AdminBenefitsOfSubsView
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
