import $ from 'jquery';
import "slick-carousel";
export default function customScript(){
  $(window).on('scroll', function () {
    var scroll = $(window).scrollTop();
    if (scroll >= 110) {
      $('body').addClass("small-header");
    } else {
      $('body').removeClass("small-header");
    }
  });

// $("#menuOpen").click(function () {
//   $("#mainContainer").toggleClass("mobile_nav_class");
// });

// $("#nextStep").click(function (event) {
//   event.preventDefault(); // Prevent the default behavior
//   $("#stepOne").toggleClass("step-hide");
//   $("#stepTwo").toggleClass("step-show");
// });
$(document).on('click', '#menuOpen', function () {
  $("#mainContainer").toggleClass("mobile_nav_class");
});

$(document).on('click', '#nextStep', function (event) {
  event.preventDefault(); // Prevent the default behavior
  $("#stepOne").toggleClass("step-hide");
  $("#stepTwo").toggleClass("step-show");
});

  $(".blog-slider").slick({
    dots: true,
    arrows: true,
    infinite: false,
    fade: false,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
  });




$(".appointment-slider").slick({
  dots: false,
  arrows: false,
  infinite: false,
  fade: false,
  speed: 600,
  slidesToShow: 1,
  slidesToScroll: 1,
});

// ADMIN  JS

$("#clickSubMenuOne").click(function () {
  $("#subMenuOne").toggleClass("submenu-show");
});

$("#editClickOne").click(function (event) {
  event.preventDefault();
  $("#editBoxkOne").toggleClass("add-list-show");
  $("#cancelClickOne").removeClass("step-show");
});

$("#editClickTwo").click(function (event) {
  event.preventDefault(); 
  $("#editBoxkTwo").toggleClass("add-list-show");
  $("#cancelClickTwo").removeClass("step-show");
});

$("#editClickThree").click(function (event) {
  event.preventDefault(); 
  $("#editBoxkThree").toggleClass("add-list-show");
  $("#cancelClickThree").removeClass("step-show");
});

$("#addNewItem").click(function (event) {
  event.preventDefault(); 
  $("#addNewItemShow").toggleClass("addnewitemshow");
});

$("#hideMenu").click(function (event) {
  event.preventDefault(); 
  $(".admin-page-row").toggleClass("hide-siderbar");
});
$("#showMenu").click(function (event) {
  event.preventDefault(); 
  $(".admin-page-row").toggleClass("hide-siderbar");
});
}