<template>
    <section id="mainContainer">
        <HeaderComponent></HeaderComponent>
        <section class="admin-mid-container">
            <div class="custom-row admin-page-row">
                <LeftSidebarComponent :activeIcon.sync="activeIcon"></LeftSidebarComponent>
                <section class="right-sidebar">
                    <div class="right-sidebar-in">
                        <div class="heading-buttons">
                            <h1>

                                {{ constants.CONTACT_US_TEXT }}

                            </h1>
                            <div class="heading-buttons-set">

                                <button class="delete-item txt-btn" :class="{ 'btn-disabled': !isAnyContactSelected }"
                                    :disabled="!isAnyContactSelected" @click="deleteSelectedContacts">

                                    {{ constants.DELETE_BUTTON }}

                                </button>
                            </div>
                        </div>
                        <div class="contact-table" data-simplebar>
                            <div class="table-view contact">
                                <div class="table-view-top">
                                    <div class="table-row">
                                        <div class="checkbox-item pd-2">
                                            <div class="checkbox">
                                                <input name="cssCheckbox" id="selectAll" type="checkbox"
                                                    class="css-checkbox" @change="toggleSelectAll"
                                                    :checked="selectedContacts.length === contacts.length && contacts.length > 0">
                                                <label for="selectAll"></label>
                                            </div>
                                        </div>
                                        <div class="contact-name-item pd-1">

                                            {{ constants.NAME_LABEL }}

                                        </div>
                                        <div class="contact-email-item pd-1">

                                            {{ constants.EMAIL_LABEL }}

                                        </div>
                                        <div class="contact-phone-item pd-1">

                                            {{ constants.CONACT_NO_LABEL }}

                                        </div>
                                        <div class="contact-amount-item pd-1">

                                            {{ constants.MESSAGES_LABEL }}

                                        </div>
                                        <div class="record-generated-item pd-1">
                                            {{ constants.RECORD_GENERATED_LABEL }}
                                        </div>
                                    </div>
                                </div>
                                <div class="table-view-btm table-scroll">
                                    <div v-for="contact in contacts" :key="contact.id" class="table-row">
                                        <div class="checkbox-item pd-2">
                                            <div class="checkbox">
                                                <input name="cssCheckbox" :id="'demo_opt_' + contact.id" type="checkbox"
                                                    class="css-checkbox" @change="toggleContactSelection(contact.id)"
                                                    :checked="selectedContacts.includes(contact.id)">
                                                <label :for="'demo_opt_' + contact.id"></label>
                                            </div>
                                        </div>
                                        <div class="contact-name-item pd-1" :title="contact.Name">
                                            {{ contact.Name }}
                                        </div>
                                        <div class="contact-email-item pd-1" :title="contact.Email">
                                            {{ contact.Email }}
                                        </div>
                                        <div class="contact-phone-item pd-1">
                                            {{ contact.Number }}
                                        </div>
                                        <div class="contact-amount-item pd-1 full-des">
                                            <span>
                                                {{ contact.Message }}
                                            </span>
                                            <div class="full-des-box">
                                                <div class="full-des-box-in">
                                                    <div class="full-des-icon"></div>
                                                    <div class="full-des-content">
                                                        <div class="full-des-content-in">
                                                            <p>{{ contact.Message }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="record-generated-item pd-1">
                                            {{ formatDate(contact.Created_at) }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="pagination-box">
                            <div class="pagination-box-in">
                                <ul>
                                    <li v-if="currentPage !== 1" class="pre-pagination"
                                        @click="changePage(currentPage - 1)">
                                        <a href="#"></a>
                                    </li>
                                    <li v-for="page in visiblePages" :key="page" class="num-pagination"
                                        @click="changePage(page)" :class="{ active: currentPage === page }">
                                        <a href="#">{{ page }}</a>
                                    </li>
                                    <li v-if="currentPage !== totalPages" class="next-pagination"
                                        @click="changePage(currentPage + 1)">
                                        <a href="#"></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </section>
        <footer class="admin-site-footer">
            <div class="container">

                {{ constants.COPYRIGHT_TEXT }}

            </div>
        </footer>
    </section>
</template>
<script lang="ts">
import { defineComponent, ref, computed } from 'vue';
import constants from '@/utils/en-US-Admin.json';
import axios from 'axios';
import customJSFunction from "@/assets/js/custom.js";
import LeftSidebarComponent from "@/components/AdminLeftSideBarComponent.vue";
import { API_BASE_URL } from '@/config/api.config';
import HeaderComponent from "@/components/HeaderComponent.vue";
import moment from 'moment';

interface Contact {
    id: number;
    Name: string;
    Email: string;
    Number: string;
    Message: string;
    Created_at: string;
    updated_at: string;
}

export default defineComponent({
    name: 'ContactUsView',
    setup() {
        const activeIcon = ref('adminContact');
        const selectedContacts = ref([] as number[]);
        const isAnyContactSelected = computed(() => selectedContacts.value.length > 0);

        return {
            activeIcon,
            selectedContacts,
            isAnyContactSelected
        };
    },
    data() {
        return {
            constants,
            contacts: [] as Contact[],
            currentPage: 1,
            totalPages: 1,
            selectedContacts: [] as number[],
        };
    },
    components: {
        HeaderComponent,
        LeftSidebarComponent
    },
    beforeRouteEnter(to, from, next) {
        const isLoggedIn = localStorage.getItem('isLoggedIn');
        if (isLoggedIn !== "true") {
            next({ name: 'LoginView' });
        } else {
            next();
        }
    },
    computed: {
        visiblePages() {
            const maxVisiblePages = 5; // Maximum number of page numbers to show
            const halfMaxVisiblePages = Math.floor(maxVisiblePages / 2);
            let start = Math.max(this.currentPage - halfMaxVisiblePages, 1);
            let end = start + maxVisiblePages - 1;

            if (end > this.totalPages) {
                end = this.totalPages;
                start = Math.max(end - maxVisiblePages + 1, 1);
            }

            return Array.from({ length: end - start + 1 }, (_, i) => start + i);
        },
    },
    created() {
        this.fetchContacts();
    },
    methods: {
        async fetchContacts() {
            try {
                const limit = 10;
                const skip = (this.currentPage - 1) * limit;
                const response = await axios.post(`${API_BASE_URL}/contactUs/`, { limit, skip });
                this.contacts = response.data.data;
                this.totalPages = Math.ceil(response.data.total_count / limit);
            } catch (error) {
                console.error('Error fetching contacts:', error);
            }
        },
        formatDate(dateStr: string) {
            let date = moment(dateStr);
            return date.format('DD/MM/YYYY hh:mm a');
        },
        changePage(page: number) {
            if (page > 0 && page <= this.totalPages) {
                this.currentPage = page;
                this.fetchContacts();
            }
        },
        toggleContactSelection(contactId: number) {
            if (this.selectedContacts.includes(contactId)) {
                this.selectedContacts = this.selectedContacts.filter(id => id !== contactId);
            } else {
                this.selectedContacts.push(contactId);
            }
        },
        toggleSelectAll() {
            if (this.selectedContacts.length === this.contacts.length) {
                this.selectedContacts = [];
            } else {
                this.selectedContacts = this.contacts.map(contact => contact.id);
            }
        },
        async deleteSelectedContacts() {
            try {
                await axios.put(`${API_BASE_URL}/contactUs/update/`, { contactIds: this.selectedContacts });
                this.contacts = this.contacts.filter(contact => !this.selectedContacts.includes(contact.id));
                this.selectedContacts = [];
                this.fetchContacts()
                if (this.contacts.length === 0) {
                    this.currentPage = this.currentPage - 1;
                    if (this.currentPage < 1) {
                        this.currentPage = 1;
                    }
                    this.fetchContacts();
                }
            } catch (error) {
                console.error('Error deleting contacts:', error);
            }
        }
    },
});
</script>