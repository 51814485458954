<template>
    <section id="mainContainer" :class="{ 'mobile_nav_class': isActive }">
        <HeaderComponent :activeIcon.sync="activeIcon"></HeaderComponent>
        <section class="mid-container">
            <section class="blog-details" v-if="Object.keys(blogData).length > 0">
                <section class="blog-details-img"
                    :style="{ 'background-image': `url(${getCdnImageAddress(blogData.BlogImage, `w_1900,h_400/`)})` }">
                    <img :src='getCdnImageAddress(blogData.BlogImage, `w_1900,h_400/`)' alt="Alt text here" />
                    <div class="blog-author">
                        <div class="blog-author-info">
                            <strong>{{ constants.DIKSHA_THAKKAR }} </strong>
                            <span>{{ currentBlogPublishedDate }}</span>
                        </div>
                        <div class="blog-author-img">
                            <img src="../../assets/img/dummy/blog-auther-img.jpg" />
                        </div>
                    </div>
                </section>
                <section class="blog-details-content">
                    <div class="container">
                        <h1>{{ blogData.title }}</h1>
                        <div class="blog-details-content-row">
                            <div class="blog-details-content-row-right">
                                <div class="blog-details-text">
                                    <div class="des">
                                        <div class="blog_short_description">
                                            <p> {{ blogData.short_description }}</p>
                                        </div>
                                        <div class="blog_description">
                                            <p v-html="blogData.description"></p>
                                        </div>

                                    </div>
                                </div>
                                <div class="blog-item-pagination" v-if="Allblogs.length > 1">
                                    <div class="blog-pagi-item previous-blog" @click="previousBlog">
                                        <div class="blog-pagi-item-img"><img
                                                :src='getCdnImageAddress(previousBlogImageUrl, `w_50,h_50/`)'
                                                alt="Alt text here" /></div>
                                        <div class="blog-pagi-item-content">
                                            <a href="#">
                                                <div class="blog-pagi-item-info">
                                                    <i><img src="../../assets/img/gray-arrow-left.svg" alt="" /></i>
                                                    <strong>
                                                        {{ constants.PREVIOUS_TEXT }}
                                                    </strong>
                                                </div>
                                                <h4 ref="previousBlogTitle">{{ trimText(previousBlogTitle, 40) }}</h4>
                                                <span class="span-ago">{{ previousBlogPublishedDate }}</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="blog-pagi-item next-blog" @click="nextBlog">
                                        <div class="blog-pagi-item-img">
                                            <img :src='getCdnImageAddress(nextBlogImageUrl, `w_50,h_50/`)'
                                                alt="Alt text here" />
                                        </div>
                                        <div class="blog-pagi-item-content">
                                            <a href="#">
                                                <div class="blog-pagi-item-info">
                                                    <strong>
                                                        {{ constants.NEXT_TEXT }}
                                                    </strong>
                                                    <i><img src="../../assets/img/gray-arrow-right.svg" alt="" /></i>

                                                </div>
                                                <h4 ref="nextBlogTitle">{{ trimText(nextBlogTitle, 40) }}</h4>
                                                <span class="span-ago">{{ nextBlogPublishedDate }} </span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>


        </section>
        <FooterComponent></FooterComponent>
    </section>
    <AboutMeComponent></AboutMeComponent>
    <ContactUsComponent></ContactUsComponent>
</template>
<script lang="ts">
import { defineComponent, ref, nextTick, computed } from 'vue';
import HeaderComponent from "@/components/HeaderComponent.vue";
import FooterComponent from "@/components/FooterComponent.vue";
import AboutMeComponent from "@/components/AboutMeComponent.vue";
import ContactUsComponent from '@/components/ContactUsComponent.vue';
import constants from '@/utils/en-US-User.json';
import customJSFunction from "@/assets/js/custom.js"
import { API_BASE_URL } from '@/config/api.config';
import axios from 'axios';
import store from '@/store';
import moment from 'moment';
import { environment } from '@/config/environment';

interface Blog {
    id: number;
    title: string;
    short_description: string;
    description: string;
    BlogImage: string;
    publishedDate: string;
}

export default defineComponent({
    mounted() {
        customJSFunction();
        nextTick(this.cacheImageAndTitleElements);
    },
    data() {
        return {
            constants,
            blogData: {} as Blog,
            blogID: localStorage.getItem('blogId'),
            Allblogs: [] as Blog[],
            currentBlogIndex: 0,
            previousBlogImgEl: null as HTMLImageElement | null,
            previousBlogTitleEl: null as HTMLElement | null,
            nextBlogImgEl: null as HTMLImageElement | null,
            nextBlogTitleEl: null as HTMLElement | null,
        };
    },
    props: {
        currentBlogIndex: {
            type: Number,
            required: true
        },
        // other props...
    },
    components: {
        HeaderComponent,
        FooterComponent,
        AboutMeComponent,
        ContactUsComponent
    },
    setup() {
        const activeIcon = ref('blog');
        const isActive = computed(() => store.state.isActive);


        return {
            activeIcon,
            isActive
        };
    },
    created() {
        this.fetchBlog()
    },
    computed: {
        previousBlogImageUrl(): string {
            const prevBlogIndex = (this.currentBlogIndex - 1 + this.Allblogs.length) % this.Allblogs.length;
            const prevBlog = this.Allblogs[prevBlogIndex];
            return prevBlog ? prevBlog.BlogImage : '';
        },
        previousBlogTitle(): string {
            const prevBlogIndex = (this.currentBlogIndex - 1 + this.Allblogs.length) % this.Allblogs.length;
            const prevBlog = this.Allblogs[prevBlogIndex];
            return prevBlog ? prevBlog.title : '';
        },
        previousBlogPublishedDate(): string {
            const prevBlogIndex = (this.currentBlogIndex - 1 + this.Allblogs.length) % this.Allblogs.length;
            const prevBlog = this.Allblogs[prevBlogIndex];
            if (prevBlog) {
                const publishedDate = moment(prevBlog.publishedDate);
                return publishedDate.fromNow();
            } else {
                return '';
            }
        },
        nextBlogImageUrl(): string {
            const nextBlogIndex = (this.currentBlogIndex + 1) % this.Allblogs.length;
            const nextBlog = this.Allblogs[nextBlogIndex];
            return nextBlog ? nextBlog.BlogImage : '';
        },
        nextBlogTitle(): string {
            const nextBlogIndex = (this.currentBlogIndex + 1) % this.Allblogs.length;
            const nextBlog = this.Allblogs[nextBlogIndex];
            return nextBlog ? nextBlog.title : '';
        },

        nextBlogPublishedDate(): string {
            const nextBlogIndex = (this.currentBlogIndex + 1) % this.Allblogs.length;
            const nextBlog = this.Allblogs[nextBlogIndex];
            if (nextBlog) {
                const publishedDate = moment(nextBlog.publishedDate);
                return publishedDate.fromNow();
            } else {
                return '';
            }
        },
        currentBlogPublishedDate(): string {
            const currentBlog = this.Allblogs[this.currentBlogIndex];
            if (currentBlog) {
                const publishedDate = moment(currentBlog.publishedDate);
                return publishedDate.fromNow();
            } else {
                return '';
            }
        }
    },
    methods: {
        getCdnImageAddress(src: string, size: string): string {
            if (!src) return '';
            let cdnUrl: string = environment.imageCdn.getHost;
            cdnUrl = cdnUrl.concat(size);
            return cdnUrl + encodeURIComponent(src);
        },
        async fetchBlog() {
            try {
                const blogId = this.blogID
                const [blogResponse, blogsResponse] = await Promise.all([
                    axios.get(`${API_BASE_URL}/blogs/${blogId}/`),
                    axios.get(`${API_BASE_URL}/blogs/`),
                ]);
                this.blogData = blogResponse.data;
                this.Allblogs = blogsResponse.data.filter((blog: { status: number; }) => blog.status === 1)
                this.currentBlogIndex = this.Allblogs.findIndex(blog => blog.id === Number(this.blogID));
            } catch (error) {
                console.error(error)
            }
        },
        cacheImageAndTitleElements() {
            this.previousBlogImgEl = this.$refs.previousBlogImg as HTMLImageElement;
            this.previousBlogTitleEl = this.$refs.previousBlogTitle as HTMLElement;
            this.nextBlogImgEl = this.$refs.nextBlogImg as HTMLImageElement;
            this.nextBlogTitleEl = this.$refs.nextBlogTitle as HTMLElement;
        },
        async previousBlog() {
            this.currentBlogIndex = (this.currentBlogIndex - 1 + this.Allblogs.length) % this.Allblogs.length;
            const previousBlog = this.Allblogs[this.currentBlogIndex];
            this.blogID = String(previousBlog.id);
            localStorage.setItem('blogId', this.blogID);

            // Update the image and title for the previous blog
            if (this.previousBlogImgEl && this.previousBlogTitleEl) {
                this.previousBlogImgEl.src = previousBlog.BlogImage;
                this.previousBlogTitleEl.textContent = previousBlog.title;
            }

            await this.fetchBlog();
        },
        async nextBlog() {
            this.currentBlogIndex = (this.currentBlogIndex + 1) % this.Allblogs.length;
            const nextBlog = this.Allblogs[this.currentBlogIndex];
            this.blogID = String(nextBlog.id);
            localStorage.setItem('blogId', this.blogID);

            // Update the image and title for the next blog
            if (this.nextBlogImgEl && this.nextBlogTitleEl) {
                this.nextBlogImgEl.src = nextBlog.BlogImage;
                this.nextBlogTitleEl.textContent = nextBlog.title;
            }

            await this.fetchBlog();
        },
        trimText(text: string, length: number) {
            if (text.length > length) {
                return text.substring(0, length) + '...';
            }
            return text;
        }

    }
});
</script>