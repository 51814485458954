<template>
    <div class="modal fade big-pop benefits-subscription-pop" id="benefitsSubscription" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="pop-row">
                    <div class="pop-row-left">
                        <BCarousel v-if="benefits.images.length > 0" class="carousel slide"
                            id="carouselExampleControlsA" controls :interval="4000">
                            <BCarouselSlide v-for="(image, index) in benefits.images" :key="image.id"
                                :style="{ 'background-image': `url(${getCdnImageAddress(image.image, `w_476,h_733/`)})` }"
                                active>
                            </BCarouselSlide>
                            <template #controls="{ onPrev, onNext }">
                                <a class="carousel-control-prev" href="#" role="button" @click.prevent="onPrev">
                                    <img src="../assets/img/yellow-arrow-left.svg" alt="" />
                                </a>
                                <a class="carousel-control-next" href="#" role="button" @click.prevent="onNext">
                                    <img src="../assets/img/yellow-arrow-right.svg" alt="" />
                                </a>
                            </template>
                        </BCarousel>
                    </div>
                    <div class="pop-row-right">
                        <button class="close-pop-btn" data-bs-dismiss="modal" aria-label="Close"></button>
                        <h2>{{ constants.BENEFITS_OF_SUBSCRIPTION_TEXT }} </h2>
                        <div class="benefits-list benefits-pop-scroll-height" ref="scrollWrapper" data-bs-simplebar>
                            <p v-html="benefits.description"></p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SimpleBar from 'simplebar';
import axios from 'axios';
import { API_BASE_URL } from '@/config/api.config';
import constants from '@/utils/en-US-User.json';
import { BCarousel, BCarouselSlide } from 'bootstrap-vue-3';
import { environment } from '@/config/environment';

interface Benefits {
    [key: string]: any;
    id: number | null;
    title: string;
    description: string;
    images: Array<{ id: number, image: string }>;
}

export default defineComponent({
    name: 'BenefitsOfSubComponent',
    data() {
        return {
            constants: constants,
            benefits: {
                id: null,
                title: '',
                description: '',
                images: [],
            } as Benefits,
            selectedFiles: [] as File[],
            imageSrc: [] as string[],
            imagePreviewed: false,
        }
    },
    components: {
        BCarousel,
        BCarouselSlide
    },
    methods: {
        fetchBenefits() {
            axios.get(`${API_BASE_URL}/benefitsOfSub/`)
                .then(response => {
                    if (response.data.length > 0) {
                        this.benefits = response.data[0];
                        this.fetchImages();
                    } else {
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
        fetchImages() {
            axios.get(`${API_BASE_URL}/BenefitImages/`)
                .then(response => {
                    if (response.data.length > 0) {
                        this.benefits.images = response.data;
                    } else {
                    }
                })
                .catch(error => {
                    console.error(error);
                });
        },
        getCdnImageAddress(src: string, size: string): string {
            if (!src) return '';
            let cdnUrl: string = environment.imageCdn.getHost;
            cdnUrl = cdnUrl.concat(size);
            return cdnUrl + encodeURIComponent(src);
        },
    },
    mounted() {
        this.fetchBenefits();
        const el = this.$refs.scrollWrapper as HTMLElement;
        if (el) {
            new SimpleBar(el);
        }
    },
});

</script>