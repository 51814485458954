import { createStore } from 'vuex'

interface State {
  isActive: boolean;
  showPopup: boolean;
}

export default createStore({
  state: {
    isActive: false,
    showPopup: false,
  } as State,
  getters: {
    isActive: (state: State) => state.isActive,
  },
  mutations: {
    toggleNavClass(state: State) {
      state.isActive = !state.isActive;
    },
    showPopup(state) {
      state.showPopup = true
    },
    hidePopup(state) {
      state.showPopup = false
    }
  },
  actions: {
  },
  modules: {
  }
})
