<template>
    <section id="mainContainer" :class="{ 'mobile_nav_class': isActive }">
        <Header :activeIcon.sync="activeIcon"></Header>
        <section class="mid-container">
            <section class="page-hero-section"
                :style="{ 'background-image': `url(${require('../../assets/img/dummy/blog-banner-img.jpg')})` }">
                <div class="container">
                    <h1>{{ constants.SERVICES_TEXT }}</h1>
                </div>
            </section>
            <section class="services-list-section">
                <div class="container">
                    <div v-for="(service, index) in services" :key="service.id" class="services-list-row"
                        :class="{ 'row-reverse': index % 2 === 1 }" data-aos="fade-up">
                        <div class="services-list-img">
                            <div class="services-list-img-in">
                                <div class="services-list-img-in-shap-top"></div>
                                <div class="services-list-img-in-border"></div>
                                <div class="service-list-image"> <img
                                        :src='getCdnImageAddress(service.image, `w_375,h_471/`)' alt="Alt text here" />
                                </div>
                                <div class="services-list-img-in-shap-bottom"></div>
                            </div>
                        </div>
                        <div class="services-list-content">
                            <h2>{{ service.title }}</h2>
                            <div class="des">
                                <p v-html="service.description"></p>
                            </div>
                            <div class="button-left" v-if="service.title.toLowerCase() === 'one on one counseling'"> <a
                                    href="#" class="primary-outline-btn" data-bs-toggle="modal"
                                    data-bs-target="#appointmentPop" tabindex="0">{{
                                    constants.BOOK_AN_APPOINTMENT_BUTTON }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </section>
        <Footer></Footer>
    </section>
    <AboutMeComponent></AboutMeComponent>
    <ContactUsComponent></ContactUsComponent>
    <AppointmentComponent></AppointmentComponent>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from '@/components/HeaderComponent.vue';
import Footer from '@/components/FooterComponent.vue';
import AboutMeComponent from "@/components/AboutMeComponent.vue";
import ContactUsComponent from "@/components/ContactUsComponent.vue";
import customJSFunction from '@/assets/js/custom.js';
import AppointmentComponent from '@/components/AppointmentComponent.vue';
import axios from 'axios';
import { API_BASE_URL } from '@/config/api.config';
import constants from '@/utils/en-US-User.json';
import store from '@/store';
import { environment } from '@/config/environment';


interface Service {
    id: number;
    title: string;
    short_description: string;
    description: string;
    image: string;
    editing: boolean;
    original?: Service;
    [key: string]: any;
}

export default defineComponent({
    name: 'ServicesView',
    data() {
        return {
            constants: constants,
        };
    },
    components: {
        Header,
        Footer,
        AboutMeComponent,
        ContactUsComponent,
        AppointmentComponent
    },
    methods: {
        getCdnImageAddress(src: string, size: string): string {
            if (!src) return '';
            let cdnUrl: string = environment.imageCdn.getHost;
            cdnUrl = cdnUrl.concat(size);
            return cdnUrl + encodeURIComponent(src);
        },
    },
    setup() {
        const activeIcon = ref('services'); // Initially set the active icon to 'services'
        const services = ref<Service[]>([]);
        const isActive = computed(() => store.state.isActive);


        const fetchServices = async () => {
            const response = await axios.get(`${API_BASE_URL}/services/`);
            services.value = response.data.sort((a: { id: number }, b: { id: number }) => a.id - b.id);
        };

        onMounted(() => {
            fetchServices();
        });

        return {
            activeIcon,
            fetchServices,
            services,
            isActive
        };
    },
    mounted() {
        customJSFunction(); // Call the function from custom.js
    },
});
</script>
